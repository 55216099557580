import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SecondaryButton, TextInput } from '@PipelineDeals/shared-react-components'
import ESignaturesSelectUser from './SelectUser'

import { emailAddressValidator } from 'ESignatures/components/ConfigurationModal'

const SigneeContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
`

const SigneeContent = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;
  margin: 8px;
`

const Header = styled.div`
  margin-bottom: 16px;

  span {
    margin-right: 8px;
    font-size: 14px;
    font-weight: bold;
  }
`

const LabelWrapper = styled.div`
  flex: 0;
`

const InputRow = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;

  > div {
    flex: 1;
  }
`

const EmailInputWrapper = styled.div`
  flex: 0;
  margin-top: 8px;
`

const SecondaryButtonWrapper = styled.span`
  white-space: nowrap;
`

const ESignaturesSignee = ({ signer, index, updateSigner, identifier, isSignerInvalid }) => {
  const [isSelectUserModalOpen, setIsSelectUserModalOpen] = useState(false)
  const order = signer.order

  const handleNameChange = (e) => {
    updateSigner(index, { ...signer, full_name: e.target.value })
  }

  const orderInWords = () => {
    const words = ['First', 'Second']
    return words[order]
  }

  const handleEmailChange = (e) => {
    updateSigner(index, { ...signer, email: e.target.value })
  }

  const handleSelectUser = (user) => {
    updateSigner(index, { ...signer, full_name: user.label, email: user.email })
    setIsSelectUserModalOpen(false)
  }

  const validateEmail = useMemo(() => {
    return emailAddressValidator(signer.email)
  }, [signer, isSignerInvalid])

  return (
    <SigneeContainer>
      <SigneeContent>
        <Header>
          <span>
            {orderInWords()} Signee ({identifier})
          </span>
        </Header>
        <LabelWrapper>
          <label>Full Name</label>
        </LabelWrapper>
        <InputRow>
          <div>
            <TextInput
              placeholder="Enter name"
              value={signer.full_name || ''}
              errorMsg={isSignerInvalid && (!signer.full_name || signer.full_name.length === 0) ? 'Required' : ''}
              onChange={handleNameChange}
            />
          </div>
          <div>
            <span style={{ marginRight: '8px' }}>or</span>
            <SecondaryButton
              content="Select from Pipeline CRM"
              style={SecondaryButtonWrapper}
              onClick={() => setIsSelectUserModalOpen(true)}
              disabled={Array.isArray(signer.linked_people) && signer.linked_people.length === 0}
            />
          </div>
        </InputRow>
        <EmailInputWrapper>
          <TextInput
            label="Email"
            errorMsg={isSignerInvalid && !validateEmail.valid ? validateEmail.errorMsg : ''}
            placeholder="Enter email"
            value={signer.email || ''}
            onChange={handleEmailChange}
          />
        </EmailInputWrapper>
      </SigneeContent>

      <ESignaturesSelectUser
        isOpen={isSelectUserModalOpen}
        onClose={() => setIsSelectUserModalOpen(false)}
        onSelectUser={handleSelectUser}
        overrideSelectUserData={signer.linked_people}
      />
    </SigneeContainer>
  )
}

ESignaturesSignee.propTypes = {
  signer: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  updateSigner: PropTypes.func.isRequired,
  identifier: PropTypes.string.isRequired,
  isSignerInvalid: PropTypes.bool.isRequired
}

export default ESignaturesSignee
