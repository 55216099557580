import {
  ACTIVITY_CREATE,
  ACTIVITY_DELETE,
  ACTIVITY_UPDATE,
  AGENDA_CREATE,
  BULK_ACTIONS,
  CREATE,
  FILE_CREATE,
  FILE_UPDATE,
  FILE_DELETE,
  FILE_TAG,
  LIST_VIEW_CREATE,
  LIST_VIEW_DELETE,
  LIST_VIEW_DUPLICATE,
  LIST_VIEW_SHARE,
  LIST_VIEW_STAR,
  LIST_VIEW_UPDATE,
  LIST_VIEW_ADD_REMOVE_COLUMNS,
  PERSON_ASSOCIATE,
  PERSON_DISASSOCIATE,
  MANAGE,
  UPDATE,
  UPDATE_OWNER,
  UPDATE_ACTUAL_CLOSE,
  ALLOWED_PRIVATE_NOTES,
  LIST_VIEW_CREATE_FOCUSED,
  QUICKBOOKS_INVOICE_CREATE,
  QUICKBOOKS_PROPOSAL_CREATE
} from 'permissions/actions'
import ownerId from 'permissions/ownerId'
import { userLevels } from 'permissions/userLevels'

export const DEAL = '@@permissions/policy/deal'

export default {
  [ACTIVITY_CREATE]: (user, options) => true,
  [ACTIVITY_DELETE]: (user, { object, objectType }) => !user.read_only || ownerId(object, objectType) === user.id,
  [ACTIVITY_UPDATE]: (user, { object, objectType }) => !user.read_only || ownerId(object, objectType) === user.id,
  [AGENDA_CREATE]: (user, options) => !user.read_only && !user.external,
  [BULK_ACTIONS]: (user, options) => !user.read_only && user.level !== userLevels.EXTERNAL,
  [CREATE]: (user, options) => !user.read_only && !user.external,
  [FILE_CREATE]: (user, options) => !user.read_only && !user.external,
  [FILE_UPDATE]: (user, options) => !user.read_only && !user.external,
  [FILE_DELETE]: (user, options) => !user.read_only && !user.external,
  [FILE_TAG]: (user, options) => !user.read_only && !user.external,
  [LIST_VIEW_CREATE]: (user, options) => !user.read_only && !user.external,
  [LIST_VIEW_DELETE]: (user, options) => !user.read_only && !user.external,
  [LIST_VIEW_DUPLICATE]: (user, options) => !user.read_only && !user.external,
  [LIST_VIEW_SHARE]: (user, options) => !user.read_only || user.external,
  [LIST_VIEW_STAR]: (user, options) => !user.read_only,
  [LIST_VIEW_UPDATE]: (user, options) => !user.read_only,
  [LIST_VIEW_ADD_REMOVE_COLUMNS]: (user, options) => user.level !== userLevels.EXTERNAL,
  [PERSON_ASSOCIATE]: (user, options) => !user.read_only && !user.external,
  [PERSON_DISASSOCIATE]: (user, options) => !user.read_only && !user.external,
  [MANAGE]: (user, options) => !user.read_only,
  [UPDATE]: (user, options) => !user.read_only,
  [UPDATE_OWNER]: (user, options) => !user.read_only && !user.external,
  [UPDATE_ACTUAL_CLOSE]: (user, options) => !user.read_only && !user.external && user.is_account_admin,
  [ALLOWED_PRIVATE_NOTES]: (user, options) => window.Features.private_notes && (user.level === userLevels.EXECUTIVE || user.is_account_admin),
  [LIST_VIEW_CREATE_FOCUSED]: user => !user.read_only && !user.external,
  [QUICKBOOKS_INVOICE_CREATE]: (user, options) => !user.read_only && !user.external,
  [QUICKBOOKS_PROPOSAL_CREATE]: (user, options) => !user.read_only && !user.external
}
