import { takeEvery } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import fetcher from 'modules/fetcher'

import * as constants from './constants'
import {
  setCalendarLoading,
  fetchCalendarSuccess,
  fetchCalendar,
  fetchCalendarUsersSettingsSuccess,
  fetchCalendarFiltersSuccess,
  updateCalendarFilterSuccess,
  selectCalendarFilter,
  selectDefaultCalendarFilter,
  selectCalendarFilterSuccess,
  createCalendarFilterSuccess,
  deleteCalendarFilterSuccess
} from './actions'

import {
  getCalendarDateRange,
  getCalendarEventTypeColors,
  getCalendarEventTypes,
  getCalendarFilters,
  getCalendarSelectedFilterId,
  getCalendarUserColors,
  getCalendarUsers
} from './selectors'
import { getCurrentUserSelector } from 'selectors'
import { getDefaultFilter } from './lib'

export function * reloadCalendarSaga ({ payload }) {
  const userIds = yield select(getCalendarUsers)
  const filter = yield select(getCalendarEventTypes)
  const range = yield select(getCalendarDateRange)

  const start = Math.floor(new Date(range.start.valueOf()))
  const end = Math.floor(new Date(range.end.valueOf()))

  yield put(setCalendarLoading(true))
  try {
    const response = yield call(
      fetcher,
      '/calendar_entries/calendar',
      {
        method: 'GET',
        query: { start: start, end: end, show: filter, user_ids: userIds }
      }
    )
    yield put(fetchCalendarSuccess(response))
    yield put(setCalendarLoading(false))
  } catch (exception) {
    yield put(setCalendarLoading(false))
    console.error(exception.message)
  }
}

export function * watchReloadCalendarSaga () {
  yield call(
    takeEvery,
    [
      constants.CALENDAR_FETCH,
      constants.CALENDAR_SET_DATE_RANGE,
      constants.CALENDAR_UPDATE_USERS,
      constants.CALENDAR_UPDATE_EVENT_TYPES
    ],
    reloadCalendarSaga
  )
}

export function * markCompleteCalendarSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `/calendar_entries/${payload.id}/${
        payload.complete ? 'mark_incomplete' : 'mark_complete'
      }`,
      {
        method: 'PUT'
      }
    )
    yield put(fetchCalendar())
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchMarkCompleteCalendarSaga () {
  yield call(
    takeEvery,
    constants.CALENDAR_MARK_COMPLETE,
    markCompleteCalendarSaga
  )
}

export function * deleteCalendarEventSaga ({ payload }) {
  try {
    let url = `/calendar_entries/${payload.id}`
    if (payload.destroyRecurrentEvents) {
      url = `${url}?delete_future_recurring_events=1`
    }

    yield call(fetcher, url, {
      method: 'DELETE'
    })
    yield put(fetchCalendar())
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchDeleteCalendarEventSaga () {
  yield call(
    takeEvery,
    constants.CALENDAR_DELETE_EVENT,
    deleteCalendarEventSaga
  )
}

export function * setCalendarUsersSettingsSaga ({ payload }) {
  const settingsPayload = {
    line_clamp: payload.lineClamp,
    start_of_week: payload.startOfWeek
  }

  try {
    yield put(fetchCalendarUsersSettingsSuccess(settingsPayload))

    const response = yield call(fetcher, '/calendar/set_settings', {
      method: 'PUT',
      body: JSON.stringify(settingsPayload)
    })
    yield put(fetchCalendarUsersSettingsSuccess(response))
    yield put(fetchCalendar())
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchSetCalendarUsersSettingsSaga () {
  yield call(
    takeEvery,
    constants.CALENDAR_SET_USERS_SETTINGS,
    setCalendarUsersSettingsSaga
  )
}

export function * fetchCalendarUserDataSaga ({ payload }) {
  try {
    const response = yield call(fetcher, '/api/internal/calendar_filters/user_data', {
      method: 'GET'
    })

    yield put(selectCalendarFilterSuccess(response))
    yield put(fetchCalendar())
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchFetchCalendarUserDataSaga () {
  yield call(
    takeEvery,
    constants.CALENDAR_FETCH_USER_DATA,
    fetchCalendarUserDataSaga
  )
}

export function * fetchCalendarUsersSettingsSaga ({ payload }) {
  try {
    const response = yield call(fetcher, '/calendar/settings', {
      method: 'GET'
    })
    yield put(fetchCalendarUsersSettingsSuccess(response))
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchFetchCalendarUsersSettingsSaga () {
  yield call(
    takeEvery,
    constants.CALENDAR_FETCH_USERS_SETTINGS,
    fetchCalendarUsersSettingsSaga
  )
}

export function * updateCalendarEventSaga ({ payload: { event, attrs } }) {
  try {
    yield put(setCalendarLoading(true))
    yield call(fetcher, `/calendar_entries/${event.attributes.id}`, {
      method: 'PUT',
      body: JSON.stringify({ calendar_entry: { ...attrs } })
    })
    yield put(fetchCalendar())
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchUpdateCalendarEventSaga () {
  yield call(
    takeEvery,
    constants.CALENDAR_UPDATE_EVENT,
    updateCalendarEventSaga
  )
}

export function * fetchCalendarFiltersSaga () {
  try {
    const response = yield call(fetcher, '/api/internal/calendar_filters', {
      method: 'GET'
    })
    yield put(fetchCalendarFiltersSuccess(response))
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchFetchCalendarFilterSaga () {
  yield call(
    takeEvery,
    constants.CALENDAR_FETCH_FILTERS,
    fetchCalendarFiltersSaga
  )
}

export function * selectCalendarFilterSaga ({ payload }) {
  const selectedFilterId = yield select(getCalendarSelectedFilterId)

  yield put(selectCalendarFilterSuccess(payload))
  yield put(fetchCalendar())

  if (payload.id && payload.id !== selectedFilterId) {
    try {
      yield call(fetcher, `/api/internal/calendar_filters/${payload.id}/select`, {
        method: 'PUT'
      })
    } catch (exception) {
      console.error(exception.message)
    }
  }
}

export function * watchSelectCalendarFilterSaga () {
  yield call(
    takeEvery,
    constants.CALENDAR_SELECT_FILTER,
    selectCalendarFilterSaga
  )
}

export function * createCalendarFilterSaga ({ payload }) {
  const filterName = payload.name
  const userIds = yield select(getCalendarUsers)
  const userColors = yield select(getCalendarUserColors)
  const eventTypeIds = yield select(getCalendarEventTypes)
  const eventTypeColors = yield select(getCalendarEventTypeColors)

  const filterPayload = {
    name: filterName,
    user_ids: userIds,
    user_colors: userColors,
    event_type_ids: eventTypeIds,
    event_type_colors: eventTypeColors
  }

  try {
    const response = yield call(fetcher, '/api/internal/calendar_filters', {
      method: 'POST',
      body: JSON.stringify(filterPayload)
    })
    yield put(createCalendarFilterSuccess(response))
    yield put(selectCalendarFilter(response))
    yield call(window.Helpers.notify, `${filterName} has been saved as a new filter.`)
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchCreateCalendarFilterSaga () {
  yield call(
    takeEvery,
    constants.CALENDAR_CREATE_FILTER,
    createCalendarFilterSaga
  )
}

export function * updateCalendarFilterSaga ({ payload }) {
  const userIds = yield select(getCalendarUsers)
  const userColors = yield select(getCalendarUserColors)
  const eventTypeIds = yield select(getCalendarEventTypes)
  const eventTypeColors = yield select(getCalendarEventTypeColors)

  const filterPayload = {
    user_ids: userIds,
    user_colors: userColors,
    event_type_ids: eventTypeIds,
    event_type_colors: eventTypeColors,
    name: payload.name
  }

  try {
    const response = yield call(fetcher, `/api/internal/calendar_filters/${payload.id}`, {
      method: 'PUT',
      body: JSON.stringify(filterPayload)
    })
    yield put(updateCalendarFilterSuccess(response))
    yield call(window.Helpers.notify, 'Calendar has been updated successfully.')
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchUpdateCalendarFilterSaga () {
  yield call(
    takeEvery,
    constants.CALENDAR_UPDATE_FILTER,
    updateCalendarFilterSaga
  )
}

export function * deleteCalendarFilterSaga ({ payload }) {
  try {
    yield call(fetcher, `/api/internal/calendar_filters/${payload.id}`, {
      method: 'DELETE'
    })
    yield put(deleteCalendarFilterSuccess(payload))
    yield call(window.Helpers.notify, 'Calendar has been deleted successfully.')
    const filters = yield select(getCalendarFilters)
    if (filters.length) {
      yield put(selectCalendarFilter(payload))
    } else {
      yield put(selectDefaultCalendarFilter())
    }
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchDeleteCalendarFilterSaga () {
  yield call(
    takeEvery,
    constants.CALENDAR_DELETE_FILTER,
    deleteCalendarFilterSaga
  )
}

export function * selectDefaultCalendarFilterSaga () {
  const currentUser = yield select(getCurrentUserSelector)

  yield put(selectCalendarFilter(getDefaultFilter(currentUser.id)))

  try {
    yield call(fetcher, '/api/internal/calendar_filters/unselect', {
      method: 'PUT'
    })
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchSelectDefaultCalendarFilterSaga () {
  yield call(
    takeEvery,
    constants.CALENDAR_SELECT_DEFAULT_FILTER,
    selectDefaultCalendarFilterSaga
  )
}
