import fetcher from 'modules/fetcher'
import { EMAIL_VALIDATION_API_URL } from './constants'

export const verifyEmail = async (
  email,
  successCallback,
  errorCallback = handleEmailVerificationError
) => {
  return callEmailVerificationEndpoint(email).then(res => {
    successCallback && successCallback(res)
  }).catch(error => {
    errorCallback && errorCallback(error)
  })
}

export const callEmailVerificationEndpoint = async (email) => {
  return fetcher(EMAIL_VALIDATION_API_URL + email, {
    method: 'GET'
  })
}

export const handleEmailVerificationError = (error) => {
  switch (error.response.status) {
    case 502:
      window.Helpers.notify('Email verification service encountered an error. Please try again later.', { type: 'error' })
      break
    case 504:
      window.Helpers.notify('Email verification service is unavailable at the moment. Please try again later.', { type: 'error' })
      break
    case 401:
      window.Helpers.notify('Email validation quota exceeded. Please try again at the end of the month.', { type: 'warning' })
      break
    default:
      window.Helpers.notify('An unexpected error occurred during email verification. Please try again later.', { type: 'error' })
  }
}
