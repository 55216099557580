const PREFIX = '@@permissions/action'

export const BULK_ACTIONS = `${PREFIX}/bulk_actions`
export const CREATE = `${PREFIX}/create`
export const DELETE = `${PREFIX}/delete`
export const INDEX = `${PREFIX}/index`
export const MANAGE = `${PREFIX}/manage`
export const SHARE = `${PREFIX}/share`
export const SYNC = `${PREFIX}/sync`
export const UPDATE = `${PREFIX}/update`
export const UPDATE_OWNER = `${PREFIX}/update_owner`
export const UPDATE_ACTUAL_CLOSE = `${PREFIX}/update_actual_close`

export const ACTIVITY_CREATE = `${PREFIX}/activity_create`
export const ACTIVITY_DELETE = `${PREFIX}/activity_delete`
export const ACTIVITY_UPDATE = `${PREFIX}/activity_update`

export const AGENDA_CREATE = `${PREFIX}/agenda_create`

export const AVATAR_UPLOAD = `${PREFIX}/avatar_upload`

export const CALENDAR_SUBSCRIBE = `${PREFIX}/calendar_subscribe`

export const COMMENT_CREATE = `${PREFIX}/comment_create`
export const COMMENT_DELETE = `${PREFIX}/comment_delete`
export const COMMENT_UPDATE = `${PREFIX}/comment_update`

export const FILE_CREATE = `${PREFIX}/file_create`
export const FILE_UPDATE = `${PREFIX}/file_update`
export const FILE_DELETE = `${PREFIX}/file_delete`
export const FILE_TAG = `${PREFIX}/file_tag`

export const LIST_VIEW_CREATE = `${PREFIX}/list_view_create`
export const LIST_VIEW_DELETE = `${PREFIX}/list_view_delete`
export const LIST_VIEW_DUPLICATE = `${PREFIX}/list_view_duplicate`
export const LIST_VIEW_CREATE_FOCUSED = `${PREFIX}/list_view_create_focused`

export const LIST_VIEW_SHARE = `${PREFIX}/list_view_share`
export const LIST_VIEW_STAR = `${PREFIX}/list_view_star`
export const LIST_VIEW_UPDATE = `${PREFIX}/list_view_update`
export const LIST_VIEW_ADD_REMOVE_COLUMNS = `${PREFIX}/list_view_add_remove_columns`

export const MILESTONE_CREATE = `${PREFIX}/milestone_create`

export const PERSON_ASSOCIATE = `${PREFIX}/person_associate`
export const PERSON_DISASSOCIATE = `${PREFIX}/person_disassociate`

export const QUICK_ADD = `${PREFIX}/quick_add`
export const GLOBAL_SEARCH = `${PREFIX}/global_search`

export const NAVIGATION_HOME = `${PREFIX}/navigation_home`
export const NAVIGATION_COMPANIES = `${PREFIX}/navigation_companies`
export const NAVIGATION_PEOPLE = `${PREFIX}/navigation_people`
export const NAVIGATION_AGENDA = `${PREFIX}/navigation_agenda`
export const NAVIGATION_CALENDAR = `${PREFIX}/navigation_calendar`
export const NAVIGATION_EMAIL = `${PREFIX}/navigation_email`
export const NAVIGATION_REPORTS = `${PREFIX}/navigation_reports`
export const NAVIGATION_GOALS = `${PREFIX}/navigation_goals`
export const NAVIGATION_FILES = `${PREFIX}/navigation_files`
export const NAVIGATION_PROFILE = `${PREFIX}/navigation_profile`
export const NAVIGATION_RECYCLE_BIN = `${PREFIX}/navigation_recycle_bin`

export const ALLOWED_PRIVATE_NOTES = `${PREFIX}/allowed_private_notes`
export const INBOX_READ = `${PREFIX}/inbox_read`
export const CAMPAIGN_SUBSCRIBE = `${PREFIX}/campaign_subscribe`

export const MERGE = `${PREFIX}/merge`

export const CUSTOM_DASHBOARD_CREATE = `${PREFIX}/custom_dashboard_create`
export const CUSTOM_DASHBOARD_DELETE = `${PREFIX}/custom_dashboard_delete`
export const CUSTOM_DASHBOARD_DUPLICATE = `${PREFIX}/custom_dashboard_duplicate`
export const CUSTOM_DASHBOARD_SHARE = `${PREFIX}/custom_dashboard_share`
export const CUSTOM_DASHBOARD_STAR = `${PREFIX}/custom_dashboard_star`
export const CUSTOM_DASHBOARD_UPDATE = `${PREFIX}/custom_dashboard_update`

export const REPORT_SHARE = `${PREFIX}/report_share`
export const REPORT_STAR = `${PREFIX}/report_star`
export const REPORT_CREATE = `${PREFIX}/report_create`
export const REPORT_UPDATE = `${PREFIX}/report_update`
export const REPORT_DELETE = `${PREFIX}/report_delete`
export const REPORT_DUPLICATE = `${PREFIX}/report_duplicate`

export const DUPLICATE_CHECK_UPDATE = `${PREFIX}/duplicate_check_update`
export const DUPLICATE_CHECK_DELETE = `${PREFIX}/duplicate_check_delete`

export const QUICKBOOKS_INVOICE_CREATE = `${PREFIX}/quickbooks_invoice_create`
export const QUICKBOOKS_PROPOSAL_CREATE = `${PREFIX}/quickbooks_proposal_create`

export const REPORTING_VIEW_CREATE = `${PREFIX}/reporting_view_create`
export const REPORTING_VIEW_DELETE = `${PREFIX}/reporting_view_delete`
export const REPORTING_VIEW_DUPLICATE = `${PREFIX}/reporting_view_duplicate`
export const REPORTING_VIEW_SHARE = `${PREFIX}/reporting_view_share`
export const REPORTING_VIEW_STAR = `${PREFIX}/reporting_view_star`
export const REPORTING_VIEW_UPDATE = `${PREFIX}/reporting_view_update`
