const checkBoolean = (trigger, value) => {
  const valueBoolean = normalizeValueBoolean(value)
  const operandBoolean = normalizeOperandBoolean(trigger.operand)

  switch (trigger.name) {
    case 'Is':
      return booleanIs(valueBoolean, operandBoolean)
    case 'IsNot':
      return booleanIsNot(valueBoolean, operandBoolean)
    default:
      return null
  }
}

export default checkBoolean

const normalizeValueBoolean = v => {
  if (v === true || v === 1 || v === '1' || v === 'true' || v === 't') return true
  if (v === false || v === 0 || v === '0' || v === 'false' || v === 'f' || v === null || v === undefined || v === '') return false
  return null
}

const normalizeOperandBoolean = v => {
  if (v === true || v === 1 || v === '1' || v === 'true' || v === 't') return true
  if (v === false || v === 0 || v === '0' || v === 'false' || v === 'f' || v === null || v === undefined || v === '') return false
  return null
}

const booleanIs = (value, operand) => {
  if (value === operand) {
    return { message: `is ${operand}` }
  }
  return null
}

const booleanIsNot = (value, operand) => {
  if (value !== operand) {
    return { message: `is not ${operand}` }
  }
  return null
}
