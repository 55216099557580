export const QUOTA_CONTENT_EXCEEDED_DEAL = 'QUOTA_CONTENT_EXCEEDED_DEAL'
export const QUOTA_CONTENT_EXCEEDED_EXPORTS = 'QUOTA_CONTENT_EXCEEDED_EXPORTS'
export const QUOTA_CONTENT_POTENTIAL_DEAL = 'QUOTA_CONTENT_POTENTIAL_DEAL'
export const QUOTA_CONTENT_EXCEEDED_CUSTOM_FIELD = 'QUOTA_CONTENT_EXCEEDED_CUSTOM_FIELD'
export const QUOTA_CONTENT_EXCEEDED_TEAMS = 'QUOTA_CONTENT_EXCEEDED_TEAMS'
export const QUOTA_CONTENT_EXCEEDED_READ_ONLY_USER = 'QUOTA_CONTENT_EXCEEDED_READ_ONLY_USER'
export const QUOTA_CONTENT_UPGRADE_READ_ONLY_USER = 'QUOTA_CONTENT_UPGRADE_READ_ONLY_USER'
export const QUOTA_CONTENT_EXCEEDED_AI_EMAIL = 'QUOTA_CONTENT_EXCEEDED_AI_EMAIL'
export const QUOTA_CONTENT_EXCEEDED_MAP_VIEWS_ROUTES = 'QUOTA_CONTENT_EXCEEDED_MAP_VIEWS_ROUTES'

export const FEATURE_REPORTING_VIEW_SCHEDULING = 'FEATURE_REPORTING_VIEW_SCHEDULING'
export const FEATURE_NATIVE_ESIGNATURES = 'FEATURE_NATIVE_ESIGNATURES'

export const QUOTA_EXCEEDED_MODAL = '@@modals/QUOTA_EXCEEDED_MODAL'

export const QUOTA_TYPE_DEAL = 'QUOTA_TYPE_DEAL'
export const QUOTA_TYPE_EMAIL_TRACKING = 'QUOTA_TYPE_EMAIL_TRACKING'
export const QUOTA_TYPE_EXPORTS = 'QUOTA_TYPE_EXPORTS'
export const QUOTA_TYPE_READ_ONLY_USER = 'QUOTA_TYPE_READ_ONLY_USER'
export const QUOTA_TYPE_CUSTOM_FIELD_DEAL = 'QUOTA_TYPE_CUSTOM_FIELD_DEAL'
export const QUOTA_TYPE_CUSTOM_FIELD_PERSON = 'QUOTA_TYPE_CUSTOM_FIELD_PERSON'
export const QUOTA_TYPE_CUSTOM_FIELD_COMPANY = 'QUOTA_TYPE_CUSTOM_FIELD_COMPANY'
export const QUOTA_TYPE_AI_EMAIL_USER = 'QUOTA_TYPE_AI_EMAIL_USER'
export const QUOTA_TYPE_EMAIL_VALIDATION = 'QUOTA_TYPE_EMAIL_VALIDATION'
export const QUOTA_TYPE_MAP_VIEWS_ROUTES = 'QUOTA_TYPE_MAP_VIEWS_ROUTES'

export const QUOTA_VERIFY = 'QUOTA_VERIFY'
export const QUOTAS_FETCH = 'QUOTAS_FETCH'
export const QUOTAS_FETCH_SUCCESS = 'QUOTAS_FETCH_SUCCESS'

export const QUOTA_TYPE_MAPPING = {
  QUOTA_TYPE_DEAL: 'deals',
  QUOTA_TYPE_READ_ONLY_USER: 'read_only_users',
  QUOTA_TYPE_EMAIL_TRACKING: 'email_tracking',
  QUOTA_TYPE_EXPORTS: 'monthly_exports_per_user',
  QUOTA_TYPE_CUSTOM_FIELD_DEAL: 'custom_fields_deal',
  QUOTA_TYPE_CUSTOM_FIELD_COMPANY: 'custom_fields_company',
  QUOTA_TYPE_CUSTOM_FIELD_PERSON: 'custom_fields_person',
  QUOTA_TYPE_AI_EMAIL_USER: 'monthly_ai_email_per_user',
  QUOTA_TYPE_EMAIL_VALIDATION: 'email_validation',
  QUOTA_TYPE_MAP_VIEWS_ROUTES: 'map_views_routes'
}
